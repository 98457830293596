export const whoaSymbolCountArr = [
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
  "W",
  "H",
  "O",
  "A",
];
